import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

interface IMainFooterProps {
  full?: boolean;
}

export default class MainFooter extends Component<IMainFooterProps> {
  render() {
    const {full = false} = this.props;

    return (
      <footer className="app-footer">
        <Container fluid={full}>
          <Row>
            <Col sm="12" md="4">
              &copy; Biedrība Drive 2 Daugavpils
            </Col>
            <Col sm="12" md="8" className="app-footer-contacts">
              <div>Контакты</div>
              <div><a href="tel:+37129786396">+371 29786396</a></div>
              <div><a href="mailto:admin@d2d.lv">admin@d2d.lv</a></div>
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
}