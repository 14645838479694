import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import ContactsWidget from '../../helpers/widgets';

const ContactsPage: React.FC = props => {
  return <>
    <MainLayout>
      <div className="page-header">
        <h1>Контакты</h1>
      </div>
      <div className="page-content">
        <ContactsWidget />
      </div>
    </MainLayout>
  </>;
};

export default ContactsPage;