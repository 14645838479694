import React, { useState } from 'react';
import { Navbar, Container, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';

interface IMainHeaderProps {
  full?: boolean;
}

const MainHeader: React.FC<IMainHeaderProps> = props => {
  const {full = false} = props;
  const [isOpen, setOpen] = useState<boolean>(false);
  const toggle = (): void => setOpen(!isOpen);

  return (
    <Navbar className="app-header" expand="lg" light>
      <Container fluid={full}>
        <NavbarBrand href="/">
          DRIVE2 Daugavpils
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink className="nav-link" exact to="/">
                Главная
              </NavLink>
            </NavItem>
            {/*<NavItem>
              <NavLink className="nav-link" to="/club-card">
                Клубная карта
              </NavLink>
            </NavItem>*/}
            <NavItem>
              <NavLink className="nav-link" to="/contacts">
                Контакты
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to="/about">
                О нас
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default MainHeader;