import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import { Container, Row, Col } from 'reactstrap';
import ContactsWidget from '../../helpers/widgets';

const IndexPage: React.FC = props => {
  return <>
    <div className="page-image page-image-dpils">
      <Container>
        <Row>
          <Col>
            <h1>DRIVE2 Daugavpils</h1>
            <div className="page-image-description">
              Сообщество машин и людей
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <MainLayout>
      <div className="page-header">
        <h1>Присоединияйся!</h1>
      </div>
      <div className="page-content">
        <ContactsWidget />
      </div>
    </MainLayout>
  </>;
};

export default IndexPage;