import React from 'react';
import { CardDeck, Card, CardHeader, CardBody, CardTitle, CardText } from 'reactstrap';
import SvgIcon from './SvgIcon';

const ContactsWidget: React.FC = props => {
  return <>
    <CardDeck className="page-cards">  
      <Card className="card-tg">
        <CardHeader tag="h3">
          <SvgIcon name="telegram" />
          Telegram Chat
        </CardHeader>
        <CardBody>
          <CardTitle>Чат участников</CardTitle>
          <CardText>Разговоры на автомобильную(и не только) тематику!</CardText>
            <a 
              href="https://t.me/joinchat/FAmzoRDjn24kwE2STdgScQ"
              className="btn btn-outline-primary"
              rel="noopener noreferrer"
              target="_blank"
            >
              Присоединиться
            </a>
          </CardBody>
      </Card>
      <Card className="card-tg">
        <CardHeader tag="h3">
          <SvgIcon name="telegram" />
          Telegram Info
        </CardHeader>
        <CardBody>
          <CardTitle>Новости и события</CardTitle>
          <CardText>Подпишись, чтобы не пропустить самое интересное!</CardText>
          <a 
            href="https://t.me/joinchat/AAAAAEY0Ee5h-Vspks-xpQ" 
            className="btn btn-outline-primary" 
            target="_blank"
            rel="noopener noreferrer"
          >
            Подписаться
          </a>
        </CardBody>
      </Card>
      <Card className="card-fb">
        <CardHeader tag="h3">
          <SvgIcon name="facebook" />
          Facebook
        </CardHeader>
        <CardBody>
          <CardTitle>Группа в Facebook</CardTitle>
          <CardText>Обьявления, обсуждения и многое другое!</CardText>
          <a 
            href="https://www.facebook.com/groups/114188652631330/" 
            className="btn btn-outline-primary" 
            target="_blank"
            rel="noopener noreferrer"
          >
            Присоединиться
          </a>
        </CardBody>
      </Card>
      <Card className="card-ig">
        <CardHeader tag="h3">
          <SvgIcon name="instagram" />
          Instagram
        </CardHeader>
        <CardBody>
          <CardTitle>Профиль в Instagram</CardTitle>
          <CardText>Фото с мероприятий, фото участников и многое другое!</CardText>
          <a 
            href="https://instagram.com/drive2daugavpils" 
            className="btn btn-outline-primary" 
            target="_blank"
            rel="noopener noreferrer"
          >
            Подписаться
          </a>
        </CardBody>
      </Card>
      <Card className="card-ig">
        <CardHeader tag="h3">
          <SvgIcon name="instagram" />
          Instagram Live
        </CardHeader>
        <CardBody>
          <CardTitle>Live Профиль в Instagram</CardTitle>
          <CardText>Все из жизни сообщества</CardText>
          <a 
            href="https://www.instagram.com/d2dlive/" 
            className="btn btn-outline-primary" 
            target="_blank"
            rel="noopener noreferrer"
          >
            Подписаться
          </a>
        </CardBody>
      </Card>
      <Card className="card-yt">
        <CardHeader tag="h3">
          <SvgIcon name="youtube" />
          Youtube
        </CardHeader>
        <CardBody>
          <CardTitle>Канал на Youtube</CardTitle>
          <CardText>Жизнь сообщества на видео</CardText>
          <a 
            href="https://www.youtube.com/channel/UCXtgKCeyJbBKPO7S-g5Ozeg/featured" 
            className="btn btn-outline-primary" 
            target="_blank"
            rel="noopener noreferrer"
          >
            Подписаться
          </a>
        </CardBody>
      </Card>
      <Card className="card-d2">
        <CardHeader tag="h3">Drive2</CardHeader>
        <CardBody>
          <CardTitle>Сообщество на Drive2.ru</CardTitle>
          <CardText>Новости, обьявления, обсуждения и многое другое!</CardText>
          <a 
            href="https://www.drive2.ru/communities/2021" 
            className="btn btn-outline-primary" 
            target="_blank"
            rel="noopener noreferrer"
          >
            Присоединиться
          </a>
        </CardBody>
      </Card>
    </CardDeck>
  </>;
};

export default ContactsWidget;