import React, { Component } from 'react';

interface ISvgIconProps {
  name: string;
  className?: string;
}

export default class SvgIcon extends Component<ISvgIconProps> {
    constructor(props: ISvgIconProps) {
      super(props);

      this.Icon = require(`!svg-react-loader?name=Icon!../../../public/images/svg/${props.name}.svg`);
    }

    private Icon: Function;

    render () {
      const {Icon} = this;
      const {className = ''} = this.props;

      return (
        <span className={`svg-icon ${className}`}>
          <Icon />
        </span>
      );
    }
};