import React from 'react';
import { Container } from 'reactstrap';

interface IMainLayoutProps {
  full?: boolean;
}

const MainLayout: React.FC<IMainLayoutProps> = ({full = false, children}) => {
  return (
    <Container 
      className="app-content" 
      fluid={full}
    >
      {children}
    </Container>
  );
}

export default MainLayout;