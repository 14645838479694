import React, { Component } from 'react';
import MainHeader from './components/layouts/common/Header';
import MainFooter from './components/layouts/common/Footer';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import IndexPage from './components/pages/index';
import AboutPage from './components/pages/about';
import CardPage from './components/pages/card';
import ContactsPage from './components/pages/contact';

import './scss/index.scss';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="app">
          <MainHeader full={false} />
          <div className="app-root">
            <Switch>
              <Route path="/" component={IndexPage} exact />
              <Route path="/club-card" component={CardPage} exact />
              <Route path="/contacts" component={ContactsPage} exact />
              <Route path="/about" component={AboutPage} exact />
              <Redirect to="/" />
            </Switch>
          </div>
          <MainFooter full={false} />
        </div>
      </Router>
    );
  }
}

export default App;
