import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import { Link } from 'react-router-dom';

const AboutPage: React.FC = props => {
  return <>
    <MainLayout>
      <div className="page-header">
        <h1>О нас</h1>
      </div>
      <div className="page-content">
        <p>
          <strong>Мы - DRIVE 2 DAUGAVPILS</strong> (сокращённо "D2D") - Сообщество машин и людей города Даугавпилс.
        </p>
        <p>
          Наша цель объединить людей с общими интересами, но разными марками автомобилей, в одно дружное автосообщество.
          В отличие от клубов, собирающих людей почитающих одну марку автомобиля, мы не делимся на фанатов конкретных 
          автопроизводителей. Нас объединяет интерес к автомобилям, совместное времяпрепровождение, интерес к усовершенствованию 
          и доработке наших автомобилей. Мы организовываем и посещаем мероприятия автомобильной и околоавтомобильной тематики,
          которых так не хватает в нашем городе.
        </p>
        <p>
          Заинтересовался? Стань частью нашего сообщества! Более подробно о нас можно прочитать <a rel="noopener noreferrer" target="_blank" href="https://chayka.lv/2019/06/05/klub-avtolyubitelej-v-daugavpilse/">здесь</a>. 
        </p>
        <p>
          Вся нужная информация находится по ссылкам на странице <Link to="/contacts">Контакты</Link>.
        </p>
      </div>
    </MainLayout>
  </>;
};

export default AboutPage;