import React from 'react';
import MainLayout from '../../layouts/MainLayout';

const CardPage: React.FC = props => {
  return <>
    <MainLayout>
      <div className="page-header">
        <h1>Клубная карта</h1>
      </div>
      <div className="page-content">
        1
      </div>
    </MainLayout>
  </>;
};

export default CardPage;